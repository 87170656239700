<template>
    <div class="content">
        <div class="head">
          任务标题：{{dataInfo.taskName}}
          <div>
            <el-button size="small" type="primary" @click="addVisible=true"
              >编辑</el-button
            >
            <el-button
              size="small"
              type="warning" 
              @click="unmountFunc"
              >{{dataInfo.status?'下架':'上架'}}</el-button
            >
            <el-button
              size="small"
              type="danger" 
              @click="delFunc"
              >删除</el-button
            >
          </div>
        </div>
        <div class="info">
          <p>创建人：</p>
          <p>任务类型：{{taskTypesOption[dataInfo.taskType]}}</p>
          <p>推荐标签：{{dataInfo.tagTxt}}</p>
          <p>参与人数：{{dataInfo.attendUser}}</p>
          <p>完成人数：{{dataInfo.finishUser}}</p>
          <p>任务等级：{{dataInfo.taskLevel}}</p>
          <p>使用房间：{{dataInfo.useRooms}}</p>
          <p>当前状态：{{dataInfo.status==0?'下架':'上架'}}</p>
          <!-- <p>剩余时间：{{dataInfo.useRooms}}</p> -->
          <p style="width:100%">已送出奖励：<span v-for="(item,index) in dataInfo.sendedRewardList" :key="index">{{item.rewardName}} / {{item.amount}}</span></p>
          <p>房主获益：<span v-for="(item,index) in dataInfo.sendedRewardList" :key="index">{{item.rewardName}} / {{item.amount}}</span></p>
        </div>
        <div class="childTask">
          <div class="childAdd">
            任务分支
            <el-button
              size="medium"
              type="warning"
              icon="el-icon-plus"
              @click="addChildFunc"
              >新增</el-button
            >
          </div>
          <el-table border :data="tableData" style="width: 99%">
            <el-table-column prop="id" label="id"></el-table-column>
            <el-table-column prop="step" label="顺序"></el-table-column>
            <el-table-column prop="branchTitle" label="任务标题"></el-table-column>
            <el-table-column prop="incomeCurrency" label="图片">
              <template slot-scope="scope">
                <img :width="50" :src="scope.row.icon" class="icon" />
              </template>
            </el-table-column>
            <el-table-column prop="taskLevel" label="所属类型">
              <template slot-scope="scope">
                {{taskTypesOptions[scope.row.validType]}}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button type="text" @click="taskUpDown(scope.row,true)">上移</el-button>
                <el-button type="text" @click="taskUpDown(scope.row,false)">下移</el-button>
                <el-button type="text" @click="editChild(scope.row)">编辑</el-button>
                <el-button type="text" @click="deleteChild(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <createTask :dataRow="dataInfo" :addVisible="addVisible" @close="addVisible = false" @refresh="getInfo"></createTask>
        <createChildTask :dataRow="branchInfo" :parentData="dataInfo" :addVisible="addChildVisible" @close="addChildVisible = false" @refresh="getInfo"></createChildTask>
    </div>
</template>

<script>
import createTask from './createTask'
import createChildTask from './createChildTask'
export default {
  data() {
    return {
      taskTypesOption: {
        '0':'限时任务',
        '1':'常规任务',
        '2':'推广任务',
      },
      taskTypesOptions: {
        '0':'分享校验',
        '1':'拉新校验',
        '2':'浏览指定页面',
        '3':'swap校验',
        '4':'分享x',
        '5':'关注x',
        '6':'点赞x',
        '7':'转账校验',
        '8':'质押校验',
        '9':'上麦聊天',
        '10':'发送礼物打赏',
        '12':'加入tg',
        '13':'房间停留任务',
      },
      addVisible:false,
      addChildVisible:false,
      dataInfo:{},
      branchInfo:{},
      id:null,
      tableData:[]
    };
  },
  components:{createTask,createChildTask},
  created() {
    this.id=this.$route.query.id
    this.getInfo()
  },
  methods: {
    addChildFunc(){
      this.addChildVisible = true
      this.branchInfo={}
    },
    editChild(row){
      this.branchInfo = row;
      this.addChildVisible=true
    },
    async getInfo() {
      let res = await this.$http.taskInfo({taskId:this.id});
      if (res) {
        this.dataInfo = res.result;
        this.tableData = res.result.branchList;
        this.getTagList();
      }
    },
    async getTagList() {
      let res = await this.$http.taskTagList({pageNo:1,pageSize:100});
      if (res) {
        let taskLabelsOptions = res.result.data;
        let tagTxt = taskLabelsOptions.filter(x=>Number(x.id) === Number(this.dataInfo.tagId))[0]?.tagName
        this.$set(this.dataInfo,'tagTxt',tagTxt)
      }
    },
    unmountFunc() {
      this.$confirm(`确定要${this.dataInfo.status?'下架':'上架'}么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.taskPublish({ id:this.dataInfo.id,status:this.dataInfo.status==0?1:0 })
          if (res) {
            this.$message.success("操作成功！");
            this.getInfo();
          }
        })
        .catch(() => {});
    },
    delFunc() {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.taskDelete({id:this.dataInfo.id})
          if (res) {
            this.$router.go(-1)
          }
        })
        .catch(() => {});
    },
    deleteChild(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.taskDeleteBranch({id:row.id})
          if (res) {
            this.getInfo();
          }
        })
        .catch(() => {});
    },
    taskUpDown(row,up){
      this.$confirm(`确定要${status?'上移':'下移'}么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.taskUpDown({id:row.id,up,step:row.step})
          if (res) {
            this.getInfo();
          }
        })
        .catch(() => {});
    }
  },
};
</script>
<style lang="scss" scoped>
.content{
  padding: 20px;
  .head{
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d3cbcb;
  }
  .info{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    border-bottom: 1px solid #d3cbcb;
    p{
      width: 50%;
      line-height: 32px;

    }
  }
  .childTask{
    .childAdd{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      
    }
  }
}
</style>